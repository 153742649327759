export const nodeFromData = (datum, included, appendFeed = false) => {
  const { attributes, relationships } = datum

  // Append '/feed' to alias path for client side rendering
  const alias = appendFeed
    ? "/feed" + attributes.path.alias
    : attributes.path.alias

  // Assemble base pageData
  let pageData = {
    title: attributes.title,
    type: datum.type.replace("--", "__"),
    alias: alias,
    created: attributes.created,
    summary: attributes.body.summary,
    body: attributes.body.processed,
    bodyFullHtml: attributes.body.value,
    comment: attributes.comment,
    drupalInternalNid: attributes.drupal_internal__nid,
    drupalId: datum.id,
    fieldImage: JSON.parse(attributes.field_cloudinary),
    fieldLike: attributes.field_like,
    relationships: relationships,
    authorName: included[0].attributes.name,
    authorImage: included[0].attributes.field_user_avatar
  }

  // Add Author information to pageData array if client side rendered
  // if (appendFeed) {
  //   const author = relationships.uid.data.id
  //   for (let i = 0; i < included.length; i++) {
  //     if (included[i].id === author) {
  //       pageData.authorName = included[i].attributes.name
  //       pageData.authorImage = included[i].attributes.field_user_avatar
  //     }
  //   }
  // }

  return pageData
}
