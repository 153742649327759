import { useStaticQuery, graphql } from "gatsby"
export const existingUrls = () => {
  const { allSitePage } = useStaticQuery(
    graphql`
      query Urls {
        allSitePage {
          edges {
            node {
              path
            }
          }
        }
      }
    `
  )
  return allSitePage.edges
}
