import React from "react"
import { Router, Redirect } from "@reach/router"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Feed from "../components/Feed"
import { existingUrls } from "../hooks/existingUrls"
import { subStrBeforeAfterChars } from "../helpers/common"

const FeedPage = ({ location: { pathname } }) => {
  const urls = existingUrls()
  const strippedPath = subStrBeforeAfterChars(pathname,'/feed','b')

  /* Redirect to existing static page before querying API */
  const checkExistingUrls = () => {
    for (let i = 0; i < urls.length; i++) {
      if (strippedPath === urls[i].node.path) {
        return urls[i].node.path
      }
    }
    return false
  }

  return (
    <Layout>
      <SEO title="CCO Feed" />
      <Router>
        <Redirect path="/feed" from="/feed" to="/" noThrow />
        {!checkExistingUrls() ? (
          <Feed pathname={strippedPath} path="/feed/*" />
        ) : (
          <Redirect
            path={pathname}
            from={pathname}
            to={checkExistingUrls()}
            noThrow
          />
        )}
      </Router>
    </Layout>
  )
}

export default FeedPage
