import React from "react"
import Comments from "../Comments"
import SidebarPostInfo from "../SidebarPostInfo"
import { CloudinaryMediaCarousel } from "../CloudinaryMedia";
// @material-ui/core components
import { Grid } from "@material-ui/core"
import withStyles from "@material-ui/core/styles/withStyles"
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery"
// Material Kit
import carouselStyle from "../../assets/jss/material-kit-pro-react/views/componentsSections/carouselStyle.jsx"
import Card from "../Card/Card"
import CardBody from "../Card/CardBody"
import imagesStyles from "../../assets/jss/material-kit-pro-react/imagesStyles"

const style = {
  ...carouselStyle,
  ...withStyles,
  ...imagesStyles,
  root: {
    flexGrow: 1,
    marginTop: "12px",
    // position: "fixed",
  },
  fullWidth: {
    maxWidth: "100%",
    height: "auto",
  },
  carousel: {
    overflow: "hidden",
  },
}

export default function FeedPostTemplate({ ...props }) {
  const isMobile = useMediaQuery("(max-width:767px)")
  const {
    pageData: {
      created,
      body,
      bodyFullHtml,
      title,
      drupalId,
      type,
      fieldImage,
      fieldLike,
      authorImage,
      authorName,
      drupalInternalNid,
    },
  } = props

  const sidebar = () => (
    <SidebarPostInfo
      postId={drupalInternalNid}
      uuid={drupalId}
      type={type}
      created={created}
      authorName={authorName}
      authorImage={authorImage}
      fieldLike={fieldLike}
    />
  )

  return (
    <div style={style.root}>
      <Grid
        id={"sidebar"}
        container
        spacing={3}
        direction="row"
        justify="center"
        alignContent="center"
      >
        {!isMobile && (
          <Grid item xs={12} md={2}>
            {sidebar()}
          </Grid>
        )}
        <Grid id={"card-wrapper"} item xs={12} md={10}>
          <Card
            id={"card"}
            style={style.carousel}
            xs={12}
            sm={12}
            md={8}
            lg={8}
          >
            <div id={"carousel-wrapper"}>
              <CloudinaryMediaCarousel
                imageArray={fieldImage}
                transformations={
                  isMobile
                    ? "q_auto,f_auto,dpr_2.0,w_1024,h_576,c_limit"
                    : "q_auto,f_auto,dpr_2.0,w_1024,h_576,c_pad"
                }
              />

              {isMobile && sidebar()}
            </div>
            <CardBody>
              <div style={style.fullWidth}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: bodyFullHtml,
                  }}
                />
              </div>
            </CardBody>
          </Card>
          <div style={{ padding: "10px" }}>
            <Comments id={drupalId} type={type} />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
