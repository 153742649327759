import React from "react"
import axios from "axios"
import * as Auth from "../../utils/Auth"
import { nodeFromData } from "../../utils/normalizeFeedPost"
import FeedPostTemplate from "./feedPost"
import { navigate } from "gatsby"
import { RefreshContent } from "react-js-pull-to-refresh"
import { Grid } from "@material-ui/core"

const baseUrl = `${process.env.GATSBY_API_URL}`

export default class Feed extends React.Component {
  _isMounted = false

  constructor(props) {
    super(props)
    this.state = {
      pageData: null,
    }
  }

  componentDidMount() {
    this._isMounted = true
    this.getPageData()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  getPageData = () => {
    if (!Auth.isBrowser) {
      return
    }

    let self = this
    const { pathname } = self.props
    const translateEndpoint = `/router/translate-path?path=${pathname}&_format=json`
    // Translate route to UUID
    axios
      .get(baseUrl + translateEndpoint)
      .then(function(response) {
        // console.log(response)
        const uuid = response.data.entity.uuid
        // Get page data with UUID
        axios
          .get(baseUrl + `/jsonapi/node/community/` + uuid + `?include=uid`)
          .then(function(res) {
            console.log(res)
            const r = res.data
            // console.log(r.body)
            if (r !== undefined) {
              self._isMounted &&
              self.setState({
                pageData: nodeFromData(
                  r.data,
                  r.included,
                  true
                ),
              })
            } else {
              {
                navigate("/")
              }
            }
          })
          .catch(function(error) {
            // console.log(error)
          })
      })
      .catch(function(error) {
        // console.log(error)
      })
  }

  // getPageData = () => {
  //   if (!Auth.isBrowser) {
  //     return
  //   }
  //
  //   let self = this
  //   const { pathname } = self.props
  //   const endpoint = "/subrequests?_format=json"
  //   const payload = [
  //     {
  //       requestId: "req-1",
  //       action: "view",
  //       uri: `/router/translate-path?path=${pathname}&_format=json`,
  //       headers: {
  //         "Accept": "application/json",
  //       },
  //     },
  //     {
  //       requestId: "resolved",
  //       action: "view",
  //       uri:
  //         baseUrl +
  //         "/jsonapi/node/community/{{req-1.body@$.entity.uuid}}?include=uid",
  //       headers: {
  //         "Accept": "application/vnd.api+json",
  //         "Content-Type": "application/vnd.api+json",
  //       },
  //       waitFor: ["req-1"],
  //     },
  //   ]
  //   axios
  //     .post(baseUrl + endpoint, payload)
  //     .then(function(response) {
  //       console.log(response)
  //       // const req = response.data["req-1"]
  //       // const parsedReq = JSON.parse(req.body)
  //       // console.log(parsedReq)
  //       const r = response.data["resolved#uri{0}"]
  //       // console.log(r.body)
  //       if (r !== undefined) {
  //         const parsedBody = JSON.parse(r.body)
  //         self._isMounted &&
  //         self.setState({
  //           pageData: nodeFromData(
  //             parsedBody.data,
  //             parsedBody.included,
  //             true
  //           ),
  //         })
  //       } else {
  //         {
  //           navigate("/")
  //         }
  //       }
  //     })
  //     .catch(function(error) {
  //       // console.log(error)
  //     })
  // }

  renderLoading = () => {
    return (
      <Grid
        id={"loading"}
        container
        spacing={3}
        direction="column"
        justify="center"
        alignContent="center"
        style={{ height: "84vh" }}
      >
        <Grid item>
          <RefreshContent />
        </Grid>
      </Grid>
    )
  }

  render() {
    if (!Auth.isBrowser) {
      return
    }

    const { pageData } = this.state
    return pageData === null ? (
      this.renderLoading()
    ) : (
      <FeedPostTemplate pageData={pageData} />
    )
  }
}
